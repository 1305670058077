<template>
	<AppLayout>
		<template #navigation>
			<HeaderNavigationTabs :tabs="tabs" />
		</template>
		<template #primary>
			<router-view></router-view>
		</template>
	</AppLayout>
</template>

<script lang="ts">
	import { defineComponent, ref } from 'vue';
	import AppLayout from '@layouts/AppLayout.vue';
	import { HeaderNavigationTab } from '@/types/layout';
	import HeaderNavigationTabs from '@components/HeaderNavigationTabs.vue';

	const tabs: HeaderNavigationTab[] = [
		{ id: 1, name: 'General', to: { name: 'settings' }, selected: false },
		{ id: 2, name: 'Password', to: { name: 'password settings' }, selected: false },
		{ id: 3, name: 'Notifications', to: { name: 'notifications settings' }, selected: false },
		{ id: 4, name: 'Plan', to: { name: 'plan settings' }, selected: false },
		{ id: 5, name: 'Billing', to: { name: 'billing settings' }, selected: true },
		{ id: 6, name: 'Team Members', to: { name: 'team settings' }, selected: false },
	];

	export default defineComponent({
		components: {
			HeaderNavigationTabs,
			AppLayout,
		},
		setup() {
			const sidebarOpen = ref(false);

			return {
				tabs,
				sidebarOpen,
			};
		},
	});
</script>
